
const Blog=[{
    id:1,
    course:"Javascript",
    courseInfo:"in this course we will learn about the basic javascript",
    courseImg:"./Images/BlogImages/javascript.jpg",
    headers:[{
        headerId:1,
        headerName:"Hooks",
        headerdesc:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi accusantium impedit quas debitis odit fugiat eum illum similique placeat eveniet fuga quis, perferendis fugit nostrum nulla. Vel fugit impedit vero.",
        content:[{
            contentId:1,
            topic:"map():",
            code:"let arr=array.map((curElem,Index,array)=>{return()})",
            desc:"map() this function is used to fetch and take a loop ",
            img:"../javascript.webp",
        },{
            contentId:2,
            topic:"map():",
            code:"let arr=array.map((curElem,Index,array)=>{return()})",
            desc:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi accusantium impedit quas debitis odit fugiat eum illum similique placeat eveniet fuga quis, perferendis fugit nostrum nulla. Vel fugit impedit vero. ",
            img:"../javascript.webp",
        }
    
    ]},{
            headerId:2,
            headerName:"anotes",
            content:[{
                contentId:2,
                topic:"amap():",
                
                code:"let arr=array.map((curElem,Index,array)=>{return()})",
                desc:"map() this function is used to fetch and take a loop ",
                img:"",
            }]},
    ]
   
},{
    id:2,
    course:"Php",
    courseInfo:"in this course we will learn about the basic PHP",
    courseImg:"./Images/BlogImages/javascript.jpg",
    headers:[{
        headerId:1,
        headerName:"",
        content:[{
            topic:"dmap():",
            code:"let arr=array.map((curElem,Index,array)=>{return()})",
            desc:"map() this function is used to fetch and take a loop ",
            img:"javascript.webp",
        }]},{
            headerId:2,
            headerName:"notes",
            content:[{
                topic:"tmap():",
                
                code:"let arr=array.map((curElem,Index,array)=>{return()})",
                desc:"map() this function is used to fetch and take a loop ",
                img:"",
            }]




            }
    ]
   
},{
    id:3,
    course:"CSS",
    courseInfo:"in this course we will learn about the basic css",
    courseImg:"./Images/BlogImages/prp.JPG",
    headers:[{
        headerId:1,
        headerName:"",
        content:[{
            topic:"map():",
            code:"let arr=array.map((curElem,Index,array)=>{return()})",
            desc:"map() this function is used to fetch and take a loop ",
            img:"javascript.webp",
        }]},{
            headerId:2,
            headerName:"notes",
            content:[{
                topic:"map():",
                
                code:"let arr=array.map((curElem,Index,array)=>{return()})",
                desc:"map() this function is used to fetch and take a loop ",
                img:"",
            }]




            }
    ]
 
},{
    id:4,
    course:"Python",
    courseInfo:"in this course we will learn about the basic javascript",
    courseImg:"./Images/BlogImages/javascript.jpg",
    headers:[{
        headerId:1,
        headerName:"Hooks",
        // asdas:"das",
        content:[{
            topic:"map():",
            code:"let arr=array.map((curElem,Index,array)=>{return()})",
            desc:"map() this function is used to fetch and take a loop ",
            img:"../javascript.webp",
        }]},{
            headerId:2,
            headerName:"notes",
            content:[{
                topic:"map():",
                
                code:"let arr=array.map((curElem,Index,array)=>{return()})",
                desc:"map() this function is used to fetch and take a loop ",
                img:"",
            }]},
    ]
   
},{
    id:5,
    course:"CSS",
    courseInfo:"in this course we will learn about the basic css",
    courseImg:"./Images/BlogImages/prp.JPG",
    headers:[{
        headerId:1,
        headerName:"",
        content:[{
            topic:"map():",
            code:"let arr=array.map((curElem,Index,array)=>{return()})",
            desc:"map() this function is used to fetch and take a loop ",
            img:"javascript.webp",
        }]},{
            headerId:2,
            headerName:"notes",
            content:[{
                topic:"map():",
                
                code:"let arr=array.map((curElem,Index,array)=>{return()})",
                desc:"map() this function is used to fetch and take a loop ",
                img:"",
            }]}
    ]
 
}
]
export default Blog;